import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.userPage || initialState;

export const selectUserPage = createSelector(
    [selectDomain],
    userPageState => userPageState,
);
