import { memo, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import './styles.scss';

import gocontactless_logo from 'assets/images/logo.png';
import { reducer, sliceKey, userActions } from 'app/common/auth/slice';
import { userSaga } from 'app/common/auth/saga';
import { selectUser } from 'app/common/auth/selectors';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';

import merchant_bg from '../../../assets/images/bg_merchant_login.jpeg';
import marketplace_bg from '../../../assets/images/marketplace_login_bg.jpg';

interface Props {}

const LoginPage = memo((props: Props) => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: userSaga });
	
	const userState = useSelector(selectUser);
    const dispatch = useDispatch();

    const MerchantSubtitle = () => {
        return <p className="subtitle">
            {"USE YOUR "} <span className="highlight">JUSTPAYTO</span> {" USERNAME & PASSWORD"}
        </p>
    }
    const MarketplaceSubtitle = () => {
        return <p className="subtitle">
            {"MARKETPLACE"}
        </p>
    }

    const [backgroundImage, setBackgroundImage] = useState("");
    const [isMerchant] =  useState(window.location.pathname);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState<boolean>(localStorage.getItem('rememberMe') === 'true')

    const onUsernameChanged = (e) => setUsername(e.target.value)
    const onPasswordChanged = (e) => setPassword(e.target.value)
    const onRememberMeChanged = (e) => {
        setRememberMe(e.target.checked)
        localStorage.setItem('rememberMe', e.target.checked)
    }

    const login = () => dispatch(userActions.login({username, password}))

    useEffect(() => {
        if (userState.result) {
            Swal.fire({
                icon: 'error',
                title: userState.result,
            })
        }

    }, [userState.result])

    useEffect(() => {
        console.log(isMerchant)
        if(isMerchant=="/login" || isMerchant=="/login/"){
            setBackgroundImage(merchant_bg)
        }else{
            setBackgroundImage(marketplace_bg)
        }
    }, [])

    return (
        userState.user ?
        <Redirect to="/marketplace" /> :<>
        <Helmet>
            { isMerchant=="/login" || isMerchant=="/login/" ? <title>Merchant Login</title> : <title>Marketplace Admin Login</title> }
        </Helmet>
        <div className="login" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${backgroundImage}` }}>
            <div className="card">
                <div className="card-body text-center">
                    <img className="logo" src={gocontactless_logo} alt="logo" />
                    <h6 className="card-title">{isMerchant=="/login" || isMerchant=="/login/" ? <MerchantSubtitle /> : <MarketplaceSubtitle />}</h6>
                    <div className="form-group">
                        <input
                            type        = "text"
                            className   = "form-control"
                            placeholder = "Username"
                            value       = {username}
                            onChange    = {onUsernameChanged} />
                    </div>
                    <div className="form-group">
                        <input
                            type        = "password"
                            className   = "form-control"
                            placeholder = "Password"
                            value       = {password}
                            onChange    = {onPasswordChanged} />
                    </div>
                    <button type="button" className="btn-login" onClick={login}>Log In</button>
                </div>
            </div>
        </div>
    </>)
});

export default LoginPage;