import { noImageUrl } from "app/pages/MerchantPage/components/Item";
import { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Item as ItemProps } from "types";
import { selectOrderingPage } from "../selectors";
import { orderingPageActions } from "../slice";
import ShowMoreText from "react-show-more-text";

const classes = {
    item          : "col-sm-12 col-md-6 col-lg-4 mb-5 row px-md-5",
    image         : "col-5 mb-3",
    detailsWrapper: "col-7 no-wrap pr-4",
    details       : "row g-0 ps-2 d-flex flex-column h-100",
    name          : "col-sm-6 fw-bold p-0",
    price         : "col-sm-6 fw-bold text-md-end p-0",
    description   : "col-12 text-muted",
    amount        : "col-12 row g-0 amount-field",
}

const amountFieldClasses = {
    counterButton: "btn border col-3 text-center amount-button",
    countLabel   : "col fw-bold d-flex align-items-center justify-content-center ",
}

export const AmountField = memo((item: ItemProps) => {
    const dispatch = useDispatch();
    const cart = useSelector(selectOrderingPage).cart;
    const cartItem = cart.find(cartItem => cartItem.item.id === item.id);
    const [amount, setAmount] = useState(cartItem?.quantity || 0);

    useEffect(() => {
        setAmount(cartItem?.quantity || 0);
    }, [cart, cartItem?.quantity])

    const add   = () => updateCart(amount+1);
    const minus = () => updateCart(amount-1 >= 0 ? amount-1 : 0);
    const updateCart = (value) => dispatch(orderingPageActions.updateCartItem({item, quantity: value}));

    return (
        <div className={classes.amount}>
            <button className={amountFieldClasses.counterButton} onClick={minus}>-</button>
            <div className={amountFieldClasses.countLabel}>{amount}</div>
            <button className={amountFieldClasses.counterButton} onClick={add}>+</button>
        </div>
    )
});

const Item = memo((item: ItemProps) => {
    return (
        <div className={classes.item}>
            <div className={classes.image}>
                <img src={item.image_url ? item.image_url : noImageUrl} width="100%" alt={item.name}/>
            </div>
            <div className={classes.detailsWrapper}>
            <div className={classes.details}>
                <div className="row" style={{height: 'max-content'}}>
                    <p className={classes.name}>{item.name}</p>
                    <small className={classes.price}>{item.price}</small>
                </div>
                <ShowMoreText
                    lines = {5}
                    more  = {<span><br/>Show more</span>}
                    less  = "Show less"
                    className = "item-description flex-fill"
                >
                    <p dangerouslySetInnerHTML = {{ __html: item.description?.replaceAll('\n', '<br/>')}}/>
                </ShowMoreText>
                <AmountField {...item} />
            </div>
            </div>
        </div>
    );
});

export default Item;