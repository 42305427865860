import { delay, call, put, select, takeLatest } from 'redux-saga/effects';
import { CoreApiErrorType } from 'types/CoreApiErrorType';

import { RootState } from 'types/RootState';
import { request } from 'utils/request';
import { orderingPageActions } from './slice';

import {
    MERCHANT_URL, GROUPS_URL, ITEMS_URL, PLACE_ORDER, DISPATCHER_FEE, MINIMUM_PURCHASE, SMS_FEE
} from 'app/common/core_api/resources';
import { Group, Store } from 'types';
import { OrderingPageState } from './types';
import { storeIsOpen } from 'utils';

export function* getMerchant() {
    yield delay(1000);

    try {
        let getOrderingPage = (state: RootState) => state?.orderingPage;
        let {merchant, groups} = yield select(getOrderingPage);

        let url = MERCHANT_URL.replace(':username', merchant.username || '');

        const data = yield call(request, url);
        if (typeof data === 'string') {
            yield put(orderingPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR));
        } else {
            yield put(orderingPageActions.loadedMerchant(data));
            if (storeIsOpen(data) && !data.closed && (!groups || groups.length == 0)) {
                yield put(orderingPageActions.getGroups());
            }
        }
    } catch (err) {
        console.log(err)
        yield put(orderingPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR));
    }
}

export function* getGroups() {
    yield delay(1000);
  
    try {
        let getMerchant = (state: RootState) => state?.orderingPage?.merchant;
        let merchant: Store = yield select(getMerchant);

        let url = GROUPS_URL.replace(':merchant_id', merchant.id?.toString() || '');

        const data = yield call(request, url);
        yield put(orderingPageActions.groupsLoaded(data));

        for (const key in data) {
            yield getItems(Number.parseInt(key))
        }
    } catch (err) {
        if (err) {
            yield put(
                orderingPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getItems(group_index: number) {
    yield delay(1000);
    
    try {
        let getGroups = (state: RootState) => state?.orderingPage?.groups;
        let groups: Array<Group> = yield select(getGroups);

        let group_id = (groups[group_index].id || '').toString();
        let url = ITEMS_URL.replace(':group_id', group_id);

        const data = yield call(request, url);
        yield put(orderingPageActions.itemsLoaded({
            group_index: group_index,
            items: data
        }));
    } catch (err) {
        if (err) {
            console.log(err)
            yield put(
                orderingPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* placeOrder() {
    yield delay(1000);
    
    try {
        let getData = (state: RootState) => state?.orderingPage;
        const data: OrderingPageState = yield select(getData);

        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...data})
        }
        const link = yield call(request, PLACE_ORDER, options);
        yield delay(1000);
        yield put(orderingPageActions.placedOrder(link));
    } catch (err) {
        if (err) {
            console.log(err)
            yield put(
                orderingPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getDispatcherFee() {
    yield delay(1000);
    
    try {
        const fee = yield call(request, DISPATCHER_FEE);
        yield put(orderingPageActions.loadDispatcherFee(+fee));
    } catch (err) {
        if (err) {
            console.log(err)
            yield put(
                orderingPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getMinimumPurchase() {
    yield delay(1000);
    
    try {
        const value = yield call(request, MINIMUM_PURCHASE);
        yield put(orderingPageActions.loadMinimumPurchase(+value));
    } catch (err) {
        if (err) {
            console.log(err)
            yield put(
                orderingPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getSmsFee() {
    yield delay(1000);
    
    try {
        const value = yield call(request, SMS_FEE);
        yield put(orderingPageActions.loadSmsFee(+value));
    } catch (err) {
        if (err) {
            console.log(err)
            yield put(
                orderingPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* orderingPageSaga() {
    yield takeLatest(orderingPageActions.getMerchant.type, getMerchant)
    yield takeLatest(orderingPageActions.getGroups.type, getGroups)
    yield takeLatest(orderingPageActions.placeOrder.type, placeOrder)
    yield takeLatest(orderingPageActions.getDispatcherFee.type, getDispatcherFee)
    yield takeLatest(orderingPageActions.getMinimumPurchase.type, getMinimumPurchase)
    yield takeLatest(orderingPageActions.getSmsFee.type, getSmsFee)
}