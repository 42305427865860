import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.orderingPage || initialState;

export const selectOrderingPage = createSelector(
    [selectDomain],
    orderingPageState => orderingPageState,
);
