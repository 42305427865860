import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.adminPage || initialState;

export const selectAdminPage = createSelector(
    [selectDomain],
    adminPageState => adminPageState,
);
