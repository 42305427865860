import React, { useEffect, useState } from "react";
import EditModal from "./EditItemModal";
import { Item as ItemProps } from 'types';

import './styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { merchantPageActions } from "../../slice";
import { selectMerchantPage } from "../../selectors";
import { createStyles, makeStyles, Switch, Theme, Typography } from "@material-ui/core";
import ShowMoreText from "react-show-more-text";

export interface Props {
    groupIndex: number
    index: number
    item: ItemProps
}

export const noImageUrl = "https://d3i98j15irv1yk.cloudfront.net/no_image.webp"

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
            },
            '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[400],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    })
);

const Item = ({groupIndex, index, item}: Props) => {
    const { image_url: imageUrl, name, description, price } = item;
    const merchantPage = useSelector(selectMerchantPage);
    const groups = merchantPage.groups;
    const items = groups && groups[groupIndex].items;
    const [available, setAvailable] = useState(item.available === 'yes');
    const [updating, setUpdating] = useState(items && items[index] && items[index].available === 'yes');
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            merchantPage.apiError === undefined &&
            merchantPage.groups &&
            merchantPage.groups[groupIndex]
        ) {
            const group = merchantPage.groups[groupIndex];
            if (group && group.items && group.items[index]) {
                setAvailable(group.items[index].available === 'yes');
                setUpdating(false);
            }
        }
    }, [groupIndex, index, merchantPage])
    
    const updateAvailability = _ => {
        setUpdating(true);
        const updateItem = {...item};
        updateItem.available = updateItem.available === 'yes' ? 'no' : 'yes';
        dispatch(merchantPageActions.setAvailable({groupIndex, index, item: updateItem}));
    }

    return (
        <div className="item">
            <div>
                <img src={imageUrl ? imageUrl : noImageUrl} alt={name} width="150px" />
            </div>
            <div className="item-details">
                <p className="item-name">{name}</p>
                <ShowMoreText
                    lines = {5}
                    more  = {<span><br/>Show more</span>}
                    less  = "Show less"
                    className = "item-description"
                >
                    <p dangerouslySetInnerHTML = {{ __html: description?.replaceAll('\n', '<br/>')}}/>
                </ShowMoreText>
                <p>{price.toFixed(2)}</p>
            </div>
            <div className="item-actions">
                <EditModal groupIndex={groupIndex} index={index} item={item} />
                {updating ? <div className="spinner-border text-primary" role="status"></div> :
                <div style={{ color: available ? '#52d869': 'rgba(0, 0, 0, 0.54)'}}>
                    <Switch
                        focusVisibleClassName={classes.focusVisible}
                        disableRipple
                        classes={classes}
                        checked={available}
                        onChange={updateAvailability}
                    />
                    <Typography variant="caption" className="d-inline-flex align-items-center">
                        <span>{!available && 'Not'} Available</span>
                    </Typography>
                </div>}
            </div>
        </div>
    )
}

export default Item;