// React, Redux libraries
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserPage } from '../../selectors';
import { userPageActions } from "../../slice";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { 
    Typography, 
    Switch,
    Grid, 
    CircularProgress, 
} from '@material-ui/core/';

// Child Components
import EditModal from './EditModal';

export const noImageUrl = "https://d3i98j15irv1yk.cloudfront.net/no_image.webp"

import './styles.scss';
import { weekdays, capitalize } from 'utils';
import { selectUser } from 'app/common/auth/selectors';
import ShowMoreText from "react-show-more-text";
export interface Props {
    data: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
            },
            '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[400],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    })
);

const Store = (props: Props) => {
    const classes  = useStyles();
    const userPage = useSelector(selectUserPage);
    const user     = useSelector(selectUser).merchant;

    const [available, setAvailability] = useState(true);
    const [updating, setUpdating]      = useState(false);
    const [toggling, setToggling]      = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        if(props.data.available=='true'){
            setAvailability(true)
        }else{
            setAvailability(false)
        }
    }, [])

    useEffect(() => {
        setUpdating(false)
    }, [userPage.apiError, userPage.trigger, userPage.available_trigger])

    useEffect(() => {
        setToggling(false);
    }, [userPage.stores])

    const updateAvailability = (id) => {
        //setUpdating(true);
        if (available){
            setAvailability(false)
        }else{
            setAvailability(true)
        }
        setUpdating(true)
        dispatch(userPageActions.setAvailable({id: id}));
    }

    const get12HourTime = time => {
        const [hour, min] = time.split(':');
        const morning = parseInt(hour) < 12;
        return `${hour == 0 || hour == 12 ? 12 : morning ? hour : hour - 12}:${min} ${morning ? 'AM' : 'PM'}`
    }
    const timeRange = operationHours => (`
        from ${get12HourTime(operationHours.start)}
        -    ${get12HourTime(operationHours.end)}
    `)
    
    const open = props.data.status == 'open'

    return (
        <div className="store">
            <Grid container spacing={3}>
                <Grid item xs={2} style={{maxHeight: "200px", maxWidth: "300px", minWidth: "270px"}}>
                    <img src={props.data.logo_url ? props.data.logo_url : noImageUrl} alt={props.data.name} width="100%" height="100%"/>
                </Grid>
                <Grid className="store-details" item xs={8}>
                    <Typography variant="h5" className="store-name">{props.data.name}</Typography>
                    <ShowMoreText
                        lines = {3}
                        more  = {<span><br/>Show more</span>}
                        less  = {<span><br/>Show less</span>}
                    >
                        <Typography style={{ color: "#9a9a9c"}} dangerouslySetInnerHTML = {{ __html: props.data.description?.replaceAll('\n', '<br/>')}}/>
                    </ShowMoreText>
                    <Typography style={{ color: "#9a9a9c"}}>{props.data.contact_number}</Typography>
                    <Typography style={{ color: "#9a9a9c"}}>{props.data.dispatcher_contact_number}</Typography>
                    <Typography style={{ color: "#9a9a9c"}}>
                        {props.data.operation_hours?.everyday && `Monday - Sunday ${timeRange(props.data.operation_hours?.everyday)}`}
                        {props.data.operation_hours?.weekdays && `Monday - Friday ${timeRange(props.data.operation_hours?.weekdays)}`}
                        {props.data.operation_hours?.weekends && `Monday - Friday ${timeRange(props.data.operation_hours?.weekends)}`}
                        {
                            weekdays.map(weekday => (
                                props.data.operation_hours &&
                                props.data.operation_hours[weekday] && 
                                <div>{`${capitalize(weekday)} ${timeRange(props.data.operation_hours[weekday])}`}</div>
                            ))
                        }
                    </Typography>
                    <Typography style={{ color: "#9a9a9c"}}>{process.env.REACT_APP_URL}/{props.data.contactless_url}</Typography>
                    <Typography style={{ color: "#9a9a9c"}}>{props.data.tags}</Typography>
                </Grid>
                <Grid className="store-actions" item xs={2}>
                    <EditModal data={props.data}/>
                    <div className="d-flex flex-column justify-content-start">
                    {!updating ?
                        <Grid item xs={12} sm={6} alignItems="center" style={{display:"flex"}}>
                            <Switch
                                focusVisibleClassName={classes.focusVisible}
                                disableRipple
                                classes={{
                                root: classes.root,
                                switchBase: classes.switchBase,
                                thumb: classes.thumb,
                                track: classes.track,
                                checked: classes.checked,
                                }}
                                checked={available}
                                onChange={() => updateAvailability(props.data.id)}
                            />
                            {available ? <Typography variant="caption" style={{ color: '#52d869'}} className=" align-middle">Available</Typography> : <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)"}}>Not Available</Typography>}
                        </Grid> : <CircularProgress color="primary" size={20}/>
                    }
                    {
                        toggling ?
                        <div className="spinner-border text-primary" role="status"></div>:
                        <div style={{ color: open ? '#52d869': 'rgba(0, 0, 0, 0.54)'}}>
                            <Switch
                                focusVisibleClassName={classes.focusVisible}
                                disableRipple
                                classes={classes}
                                checked={open}
                                onChange={() => {
                                    setToggling(true);
                                    dispatch(userPageActions.toggleStore(props.data.contactless_url || ''))
                                }}
                            />
                            <Typography variant="caption" className="d-inline-flex align-items-center align-middle">
                                <span>Store is <br/>{open ? "Open" : "Closed"}</span>
                            </Typography>
                        </div>
                    }
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Store;