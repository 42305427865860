export const API_URL               = `${process.env.REACT_APP_CORE_URL}/admin/api/v3`;
export const CONTACTLESS_API_URL   = `${API_URL}/gocontactless`;
export const MARKETPLACE_LOGIN_URL = `${CONTACTLESS_API_URL}/marketplace_login`;
export const LOGIN_URL             = `${CONTACTLESS_API_URL}/login`;
export const MERCHANT_URL          = `${CONTACTLESS_API_URL}/merchant/:username`;
export const TOGGLE_STORE          = `${CONTACTLESS_API_URL}/toggle-store/:username`;
export const GROUPS_URL            = `${CONTACTLESS_API_URL}/groups/:merchant_id`;
export const ITEMS_URL             = `${CONTACTLESS_API_URL}/items/:group_id`;
export const GROUP_URL             = `${CONTACTLESS_API_URL}/group`;
export const ITEM_URL              = `${CONTACTLESS_API_URL}/item/:id`;
export const USERS_URL             = `${CONTACTLESS_API_URL}/users`;
export const SEARCH_USERS_URL      = `${CONTACTLESS_API_URL}/search_users/:keyword`;
export const ADD_USER_URL          = `${CONTACTLESS_API_URL}/add_user`;
export const SAVE_USER_URL         = `${CONTACTLESS_API_URL}/save_user`;
export const DELETE_USER_URL       = `${CONTACTLESS_API_URL}/delete_user/:id`;
export const STORES_URL            = `${CONTACTLESS_API_URL}/stores/:marketplace_user_id`;
export const ADD_STORE_URL         = `${CONTACTLESS_API_URL}/add_store`;
export const SAVE_STORE_URL        = `${CONTACTLESS_API_URL}/save_store`;
export const DELETE_STORE_URL      = `${CONTACTLESS_API_URL}/delete_store/:id`;
export const GET_STORES_URL        = `${CONTACTLESS_API_URL}/end_user_stores/:username`;
export const GET_USER_URL          = `${CONTACTLESS_API_URL}/end_user_user/:username`;
export const GET_FILTERS_URL       = `${CONTACTLESS_API_URL}/get_filters/:username`;
export const PLACE_ORDER           = `${CONTACTLESS_API_URL}/place-order`;
export const STORE_AVAILABLE_URL   = `${CONTACTLESS_API_URL}/set_store_availability/:id`;
export const GENERATE_QR           = `${CONTACTLESS_API_URL}/generate_qr/:id`;
export const ADMIN_GENERATE_QR     = `${CONTACTLESS_API_URL}/admin_generate_qr/:username`;
export const ADMIN_UPLOAD_QR       = `${CONTACTLESS_API_URL}/admin_upload_qr/:username`;
export const SAVE_OPERATION_HOURS  = `${CONTACTLESS_API_URL}/save-operation-hours/:username`;
export const CONFIRM_ORDER         = `${CONTACTLESS_API_URL}/confirm-order?token=:token`;
export const DISPATCHER_FEE        = `${CONTACTLESS_API_URL}/dispatcher-fee`;
export const MINIMUM_PURCHASE      = `${CONTACTLESS_API_URL}/minimum-purchase`;
export const SMS_FEE               = `${CONTACTLESS_API_URL}/sms-fee`;
export const STORE_NAME            = `${CONTACTLESS_API_URL}/store-name/:username`;

export const FRONTEND  = `${process.env.REACT_APP_CORE_URL}/frontend/api/v3/onboarding`;
export const COUNTRIES = `${FRONTEND}/countries`;