import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { CoreApiErrorType } from 'types/CoreApiErrorType';

import { RootState } from 'types/RootState';
import { request } from 'utils/request';
import { marketplacePageActions } from './slice';

import { PayloadAction } from '@reduxjs/toolkit';
import {
    ADD_STORE_URL,
    DELETE_STORE_URL,
    GET_FILTERS_URL,
    GET_STORES_URL,
    GET_USER_URL,
    GROUPS_URL,
    GROUP_URL,
    ITEMS_URL,
    ITEM_URL,
    SAVE_STORE_URL
} from 'app/common/core_api/resources';
import { Group, Item, Store } from './types';


export function* getStores(action: PayloadAction<{
    username
}>) {
    yield delay(1000);
  
    try {
        let getUserId = (state: RootState) => action.payload;
        let id: string = yield select(getUserId);

        let url = GET_STORES_URL.replace(':username', id);

        const data = yield call(request, url);
        for (const item of data) {
            item.storesLoading = true;
        }
        yield put(marketplacePageActions.storesLoaded(data));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getUser(action: PayloadAction<{
    username
}>) {
    yield delay(1000);
  
    try {
        let getUserId = (state: RootState) => action.payload;
        let id: string = yield select(getUserId);

        let url = GET_USER_URL.replace(':username', id);
        
        const data = yield call(request, url);
        
        data.userLoading = true;

        yield put(marketplacePageActions.userLoaded(data));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getFilters(action: PayloadAction<{
    username
}>) {
    yield delay(1000);
  
    try {
        let getUserId = (state: RootState) => action.payload;
        let id: string = yield select(getUserId);

        let url = GET_FILTERS_URL.replace(':username', id);
        
        const data = yield call(request, url);
        
        data.filtersLoading = true;

        yield put(marketplacePageActions.filtersLoaded(data));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* addStore(action: PayloadAction<{
    store?: Store
}>) {
    yield delay(1000);
    var store = action.payload
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...store})
        }
        let url = ADD_STORE_URL;
        store = (yield call(request, url, options))[0];
        yield put(marketplacePageActions.addedStore(store));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* saveStore(action: PayloadAction<{
    store?: Store
}>) {
    yield delay(1000);
    var store = action.payload
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...store})
        }
        let url = SAVE_STORE_URL;
        store = (yield call(request, url, options))[0];
        yield put(marketplacePageActions.savedStore(store));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* deleteStore(action: PayloadAction<{id: number}>) {
    yield delay(1000);
    var id = action.payload
    try {
        let options: RequestInit = {
            method: "DELETE",
        }
        let url = DELETE_STORE_URL.replace(':id', (id.id || '').toString());
        
        yield call(request, url, options);
        yield put(marketplacePageActions.deletedStore(id));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getGroups() {
    yield delay(1000);
  
    try {
        let getUserId = (state: RootState) => state?.merchantPage?.merchant_id;
        let merchant_id: string = yield select(getUserId);

        let url = GROUPS_URL.replace(':merchant_id', merchant_id);

        const data = yield call(request, url);
        for (const item of data) {
            item.itemsLoading = true;
        }
        yield put(marketplacePageActions.groupsLoaded(data));

        for (const key in data) {    
            yield getItems(Number.parseInt(key))
        }
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}


export function* getItems(group_index: number) {
    yield delay(1000);
    
    try {
        let getGroups = (state: RootState) => state?.merchantPage?.groups;
        let groups: Array<Group> = yield select(getGroups);

        let group_id = (groups[group_index].id || '').toString();
        let url = ITEMS_URL.replace(':group_id', group_id);

        const data = yield call(request, url);
        yield put(marketplacePageActions.itemsLoaded({
            group_index: group_index,
            items: data
        }));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* saveGroup(action: PayloadAction<{
    index?: number,
    group?: Group
}>) {
    yield delay(1000);
    var {index, group} = action.payload
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...group})
        }
        let url = GROUP_URL + (group?.id && `/${group?.id}` || '');
        group = (yield call(request, url, options))[0];
        yield put(marketplacePageActions.savedGroup({index, group}));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* deleteGroup(action: PayloadAction<{index: number, id: number}>) {
    yield delay(1000);
    const {id} = action.payload
    try {
        let options: RequestInit = {
            method: "DELETE",
        }
        let url = GROUP_URL + (id && `/${id}` || '');
        yield call(request, url, options);
        yield put(marketplacePageActions.deletedGroup(action.payload));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* saveItem(action: PayloadAction<{
    groupIndex: number,
    index?: number,
    item: Item
}>) {
    yield delay(2000);
    var {groupIndex, index, item} = {...action.payload}
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...item})
        }
        let url = item.id? ITEM_URL.replace(':id', `${item.id}`): ITEM_URL.replace('/:id', '')
        item = (yield call(request, url, options))[0];
        yield put(marketplacePageActions.savedItem({groupIndex, index, item}));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* deleteItem(action: PayloadAction<{groupIndex: number, index: number, id: number}>) {
    yield delay(1000);
    const {groupIndex, index, id} = action.payload
    try {
        let options: RequestInit = {
            method: "DELETE",
        }
        let url = ITEM_URL.replace(':id', (id || '').toString());
        yield call(request, url, options);
        yield put(marketplacePageActions.deletedItem({groupIndex, index}));
    } catch (err) {
        if (err) {
            yield put(
                marketplacePageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* userPageSaga() {
    yield takeLatest(marketplacePageActions.getStores.type, getStores)
    yield takeLatest(marketplacePageActions.getUser.type, getUser)
    yield takeLatest(marketplacePageActions.getFilters.type, getFilters)

    yield takeLatest(marketplacePageActions.addStore.type, addStore)
    yield takeLatest(marketplacePageActions.saveStore.type, saveStore)
    yield takeLatest(marketplacePageActions.deleteStore.type, deleteStore)
    yield takeLatest(marketplacePageActions.getGroups.type, getGroups)
    yield takeLatest(marketplacePageActions.saveGroup.type, saveGroup)
    yield takeLatest(marketplacePageActions.deleteGroup.type, deleteGroup)
    yield takeLatest(marketplacePageActions.saveItem.type, saveItem)
    yield takeLatest(marketplacePageActions.deleteItem.type, deleteItem)
    yield takeEvery(marketplacePageActions.setAvailable.type, saveItem)
}