import { memo, useState } from "react";

interface Props {
    fromMarketplace: boolean
    tableNo: number
}

const TableNumberModal = memo(({fromMarketplace, tableNo}: Props) => {
    const [show, setShow] = useState(true);

    return (<>
        <div className={`modal fade ${(show && 'show')} text-dark`} role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body text-center fw-bold p-5">
                        <h2 className="fw-bold">GoContactless!</h2>
                        <p>Enjoy the convenience of contactless and cashless transactions.</p>
                        <br/>
                        <p>TABLE NUMBER</p>
                        <div className="border mx-auto text-center mb-5" style={{height: '125px', width: '125px'}}>
                            <h1 className="fw-bold align-middle" style={{fontSize: '80px', padding: '10px 0'}}>{tableNo}</h1>
                        </div>
                        <button type="button" className="btn btn-primary btn-lg btn-block w-100" style={{backgroundColor: "#0099dd"}} onClick={e => setShow(false)}>CONTINUE</button>
                    </div>
                </div>
            </div>
        </div>
        {show && <div className={`modal-backdrop fade ${(show && 'show')}`} onClick={e => setShow(false)}></div>}
    </>)
})

export default TableNumberModal;