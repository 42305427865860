// React, Redux libraries
import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Helmet } from 'react-helmet-async';

import { 
    Typography, 
    Modal, 
    Backdrop, 
    Fade, Grid, 
    Paper, 
    FormControlLabel, 
    Button, 
    CircularProgress, 
    Radio,  
    Card,
} from '@material-ui/core/';

// Component dependencies
import { sliceKey, reducer, userPageActions } from './slice'
import { userPageSaga } from './saga'
import { selectUserPage } from './selectors';

// Child Components
import Store from './components/Stores';
import AddModal from './components/Stores/AddStoreModal';

import UserDetails from './components/UserDetails/';

// Resources
import './styles.scss';
import gocontactless_logo from 'assets/images/logo.png';
import { selectUser } from 'app/common/auth/selectors';
import { userActions } from 'app/common/auth/slice';
import { CONTACTLESS_API_URL } from 'app/common/core_api/resources';

const UserPage = memo(() => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: userPageSaga });

    const dispatch = useDispatch();
    const userPage = useSelector(selectUserPage);
    const user     = useSelector(selectUser).user;

    const myMarketplace = process.env.REACT_APP_URL + "/marketplace/" + user?.username;

    useEffect(() => {
        if (userPage.storesLoading) {
            dispatch(userPageActions.getStores(user?.id));
        }
        if (userPage.userLoading) {
            dispatch(userPageActions.getUser(user?.username));
        }
    }, [])
    
    const subtitle = useState("Preview");

    const marketplaceDownloadUrl = `${CONTACTLESS_API_URL}/marketplace/qr/${user?.id}`;

    return (<>
        <Helmet><title>{userPage.user['name'] || 'Loading'} - Marketplace Admin</title></Helmet>
        <div className="user">
            <div className="side-bar">
                <header><img src={gocontactless_logo} alt="logo" /><br/><Typography variant="caption" style={{marginTop: "-20px"}}>MARKETPLACE</Typography></header>
                {userPage.userLoading ? <></> : <>
                    {
                        userPage.user ? <>{userPage.user['qr_url'] ?
                                <div className="main">
                                    <img src={userPage.user['qr_url']} width="100%" alt="qr" />
                                    {/* <a style={{overflowWrap: "break-word"}} href="">https://www.gocontactless.at/marketplace/{userPage.user['username']}</a> */}
                                    <a href={marketplaceDownloadUrl} className="btn btn-download" download target="_blank" rel="noreferrer">Download</a>
                                </div> :
                                <div className="main secondary-text">No assigned QR code.</div>
                            }</> :
                        <div className="spinner-border text-primary" role="status"></div>
                    } </>
                }
            </div>
            <div className="content">
                <header>
                    <div>
                        {userPage.userLoading ? <></> : <>
                            {
                                userPage.user ? <><legend>{userPage.user['name']}</legend><h6><a className="non-link" href={myMarketplace} target="_blank" rel="noreferrer">{subtitle}</a></h6></> :
                                <div className="spinner-border text-primary" role="status"></div>
                            } </>
                        }
                        
                    </div>
                    <button className="btn-logout" onClick={e => dispatch(userActions.logout())}>Logout</button>
                </header>
                <hr />
                <div className="main">
                    {userPage.userLoading ? <></> : <>
                        {userPage.user ? <UserDetails data={userPage.user} /> :<></>} </>
                    }
                    <Card style={{padding: "10px"}}>
                        {userPage.storesLoading ? <CircularProgress color="primary" size={20}/> : <>
                            {
                                userPage.stores ? userPage.stores?.map(data => 
                                    <Store data={data}/>
                                ) :
                                <div className="spinner-border text-primary" role="status"></div>
                            }
                            {userPage.stores && <AddModal />}</>
                        }
                    </Card>
                </div>
            </div>
        </div>
    </>)
});

export default UserPage;