import { useEffect, useState } from 'react';

import 'styles/modal.scss'
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserPage } from 'app/pages/UserPage/selectors';
import { userPageActions } from 'app/pages/UserPage/slice';
import { User } from 'app/pages/UserPage/types';
import Swal from 'sweetalert2'


export interface Props {
    data: any
}

const EditModal = (props: Props) => {
    const userPage                                  = useSelector(selectUserPage);
    const [user]                                    = useState<User>(props.data);
    const [deleting]                                = useState(false);
    const [name, setName]                           = useState<string>('');
    const [, setUsername]                           = useState<string>('');
    const [dispatcherContact, setDispatcherContact] = useState<string>('');
    const [, setEmail]                              = useState<string>('');
    const [, setPassword]                           = useState<string>('');
    const [description, setDescription]             = useState<string>('');
    const [image, setImage]                         = useState<string>('');
    const [qr_image, setQRImage]                    = useState<string>('');
    const [show, setShow]                           = useState(false);
    const [saving, setSaving]                       = useState(false);

    const {getRootProps, getInputProps} = useDropzone();

    const dispatch = useDispatch();
    
    useEffect(() => {
        setSaving(false);
        setName(props.data.name);
        setUsername(props.data.username);
        setDispatcherContact(props.data.dispatcher_contact);
        setEmail(props.data.email);
        setPassword(props.data.password);
        setDescription(props.data.description);
        setImage(props.data.avatar_url);
        setQRImage(props.data.qr_url)
        setShow(false);
    }, [userPage.trigger, dispatch])
    
    const save = _ => {
        if(!name || !description || !image){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required',
            })
        }else{
            setSaving(true);
            dispatch(userPageActions.saveUser({
                user: {
                    id: props.data.id,
                    name: (name || ''),
                    username: (user.username || ''),
                    dispatcher_contact: (dispatcherContact || ''),
                    email: (''),
                    password: (''),
                    description: (description || ''),
                    avatar_url: (image || ''),
                    qr_url: (qr_image || '')
                }
            }))
        }
    };

    const getImageUri = e => {
        if (e.target.files) {
            const file = e.target.files[0];
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            if (file.size > 1000000) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File size exceeds limit, it should be not more than 1MB',
                })
                return;
            }
            if (!['jpg', 'jpeg', 'png'].includes(ext)) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File size exceeds limit, it should be not more than 1MB',
                })
                return;
            }
            
            if (file) {
                var reader = new FileReader();
                reader.onload = function (data) {
                    setImage(String(data.target?.result));
                }
                reader.readAsDataURL(file);
            }
            else {
                console.log('File not found');
            }
        }
    }

    const isNumber = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        if (key.length == 0) return;
        var regex = /^[ +)(0-9,\b-]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    return (<>
    <div className={`modal fade ${(show && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={() => setShow(false)}>
        <div className="modal-dialog modal-dialog-centered" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-body">
                    <h4 className="modal-title text-center mb-3">
                        Edit Marketplace
                    </h4>
                    <div className="px-5">
                        <div className="form-group mb-3">
                            <label htmlFor="name">Name *</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {50}
                                value       = {name}
                                onChange    = {e => setName(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="dispatcherContact">Dispatcher's Contact Number *</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {12}
                                value       = {dispatcherContact}
                                onChange    = {e => setDispatcherContact(e.target.value)}
                                onKeyPress  = {e => isNumber(e)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description">Description *</label>
                            <textarea
                                className   = "form-control"
                                maxLength   = {200}
                                value       = {description}
                                onChange    = {e => setDescription(e.target.value)}>
                            </textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="image">Image *</label>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} onChange={getImageUri} />
                                {!image ?
                                    <div className="text-center">
                                        <p>Click to upload</p>
                                        <small>jpg, jpeg, png (recommended 1080 x 600,  max 1 mb)</small>
                                    </div> :
                                    <img src={image} alt={user.name} width="100%"/>
                                }
                            </div>
                        </div>
                        <button className="btn btn-primary w-100 mb-2" disabled={saving || deleting} onClick={save}>{
                            !saving ? "SAVE" :
                            <div className="spinner-border text-light" role="status"></div>
                        }</button>
                        <button className="btn btn-link text-primary w-100  mb-2" onClick={() => setShow(false)} disabled={saving}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {show && <div className={`modal-backdrop fade ${(show && 'show')}`}></div>}
    <button className="btn-edit" onClick={() => setShow(true)}>
        Edit
    </button>
    </>)
}

export default EditModal;