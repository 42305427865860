import { memo }                     from 'react';
import { useSelector }              from 'react-redux';
import { Switch, Route, Redirect }  from 'react-router-dom';
import {
    CallbackComponent as CallbackComponentOidc,
    reducer as admin_reducer
} from 'redux-oidc';

import { User } from 'oidc-client';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import AdminPage       from 'app/pages/AdminPage';
import AdminLoginPage  from 'app/pages/AdminLoginPage/Loadable';
import HomePage        from 'app/pages/HomePage';
import LoginPage       from 'app/pages/LoginPage';
import MarketplacePage from 'app/pages/MarketplacePage';
import MerchantPage    from 'app/pages/MerchantPage';
import OrderingPages   from 'app/pages/OrderingPages';
import UserPage        from 'app/pages/UserPage';

import { selectUser, selectAdmin }    from 'app/common/auth/selectors';
import { sliceKey, reducer }          from 'app/common/auth/slice';
import { userSaga }                   from 'app/common/auth/saga';
import userManager                    from 'app/common/auth/user_manager';
import GoogleAnalytics, { GAWrapper } from './common/ga';

import { selectMarketplacePage } from './pages/MarketplacePage/selectors';
import { selectOrderingPage }    from './pages/OrderingPages/selectors';
import { selectUserPage }        from './pages/UserPage/selectors';
import ConfirmOrder from './pages/ConfirmOrder';

const Routes = memo(() => {
    if (
        window.location.pathname=="/marketplace/admin" ||
        window.location.pathname=="/marketplace/admin/dashboard"
    ) {
        useInjectReducer({ key: 'oidc', reducer: admin_reducer });
    } else {
        useInjectReducer({ key: sliceKey, reducer: reducer });
        useInjectSaga   ({ key: sliceKey, saga: userSaga });
    }
    
    const admin           = useSelector(selectAdmin);
	const userState       = useSelector(selectUser);
    const userPage        = useSelector(selectUserPage);
    const orderingPage    = useSelector(selectOrderingPage);
    const marketplacePage = useSelector(selectMarketplacePage);

    const isAuthenticated         = () => userState.user !== undefined && userState.user != null;
    const isMerchantAuthenticated = () => userState.merchant !== undefined && userState.merchant != null;
    const keyCAuthenticated       = () => admin !== undefined && admin != null;

    const AdminLogout = () => {
        userManager.signoutRedirect();
        return <Redirect to="/marketplace/admin"/>;
    };

    const loginSuccess = () => {
        window.location.reload();
        return <Redirect to="/marketplace/admin/dashboard"/>
    };

    const loginFailed = () => {
        return <Redirect to="/marketplace/admin"/>
    };
    
    const usersPageViewAnalytics = () => {
        if (userPage.user['name']) {
            GoogleAnalytics.send("pageview")
        }
    }

    const marketplacePageViewAnalytics = () => {
        if (marketplacePage.user['name']) {
            GoogleAnalytics.send("pageview")
        }
    }

    const orderingPageViewAnalytics = () => {
        if (orderingPage.merchant?.name) {
            GoogleAnalytics.send("pageview")
        }
    }

    return(
        <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/auth/callback"
                render={props => (
                    <CallbackComponentOidc
                        userManager={userManager}
                        successCallback={(admin: User) => {
                            props.history.replace('/marketplace/admin/dashboard');
                            loginSuccess();
                        }}
                        errorCallback={error => {
                            console.log('callback error', error);
                            props.history.replace('/marketplace/admin');
                            loginFailed();
                        }}
                    >
                        <div>Redirecting...</div>
                    </CallbackComponentOidc>
                )}
            />

        {/* Marketplace Admin Routes */}
            <Route exact path="/admin_logout" component={AdminLogout} />
            <Route exact path = "/marketplace/admin"
                render = { () =>
                    !keyCAuthenticated() ?
                    <GAWrapper>
                        <AdminLoginPage />
                    </GAWrapper>:
                    <Redirect to="/marketplace/admin/dashboard" />
                }
            />
            <Route exact path = "/marketplace/admin/dashboard"
                render = { () =>
                    keyCAuthenticated() ?
                    <GAWrapper>
                        <AdminPage />
                    </GAWrapper>:
                    <Redirect to="/marketplace/admin" />
                }
            />

        {/* Marketplace Routes */}
            <Route exact path = "/marketplace"
                render = { () =>
                    !isAuthenticated() ?
                    <GAWrapper>
                        <LoginPage />
                    </GAWrapper>:
                    <Redirect to="/marketplace/dashboard" />
                }
            />
            <Route exact path = "/marketplace/dashboard"
                render = { () =>
                    isAuthenticated() ?
                    <GAWrapper pageViewAnalytics={usersPageViewAnalytics}>
                        <UserPage />
                    </GAWrapper>:
                    <Redirect to="/marketplace" />
                }
            />
            <Route exact path = "/marketplace/:username" render = {() =>
                <GAWrapper pageViewAnalytics = {marketplacePageViewAnalytics}>
                    <MarketplacePage />
                </GAWrapper>
            }/>

        {/* Merchant Routes */}
            <Route exact path = "/login"
                render = { () =>
                    !isMerchantAuthenticated() ?
                    <GAWrapper>
                        <LoginPage />
                    </GAWrapper>:
                    <Redirect to="/merchant" />
                }
            />
            <Route exact path = "/merchant"
                render = { () =>
                    isMerchantAuthenticated() ?
                    <GAWrapper>
                        <MerchantPage />
                    </GAWrapper>:
                    <Redirect to="/login" />
                }
            />
            <Route path = "/confirm-order" component = {ConfirmOrder}/>
            <Route path = "/:merchantCode" render = {() =>
                <GAWrapper pageViewAnalytics = {orderingPageViewAnalytics}>
                    <OrderingPages />
                </GAWrapper>
            }/>

        {/* Redirect Non Existing Pages */}
            <Route path="/" render={props => (
                <Redirect to={{
                    pathname: '/',
                    state: { from: props.location }
                }}/>
            )}/>
        </Switch>
    )
});

export default Routes;