import { PayloadAction } from '@reduxjs/toolkit';
import { Group, Item, Store } from 'types';
import { CoreApiErrorType } from 'types/CoreApiErrorType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { CartItem, ContactDetails, ContainerState } from './types';


// The initial state of the ReportsPage container
export const initialState: ContainerState = {
    merchant: {},
    contacts: {
        mobile_number: {
            country: 'PH',
            prefix: '+63',
            number: ''
        }
    },
    cart: [],
    payLater: false,
    payWithCash: false,
    placedOrder: false,
    paymentPage: '',
    apiError: undefined,
};

const orderingPageSlice = createSlice({
    name: 'orderingPage',
    initialState,
    reducers: {
        getMerchant(state, action: PayloadAction<string>) {
            state.merchant.username = action.payload; 
        },
        loadedMerchant(state, action: PayloadAction<Store>) {
            state.merchant = action.payload;
        },
        getGroups(state) {},
        groupsLoaded(state, action: PayloadAction<Array<Group>>) {
            state.groups = action.payload;
        },
        itemsLoaded(state, action: PayloadAction<{
            group_index: number,
            items: Array<Item>
        }>) {
            const data = action.payload
            if (state.groups) {
                state.groups[data.group_index].items = data.items
            }
        },
        updateCartItem(state, action: PayloadAction<CartItem>) {
            const item = state.cart.findIndex(cart => (
                cart.item.id === action.payload.item.id
            ))
            if (item !== undefined && state.cart[item] !== undefined) {
                state.cart[item].quantity = action.payload.quantity;
            } else {
                state.cart.push(action.payload);
            }
            state.cart = state.cart.filter(cart => cart.quantity > 0);
        },
        resetCart(state) {
            state.cart = [];
        },
        setContactDetails(state, action: PayloadAction<ContactDetails>) {
            state.contacts = action.payload;
        },
        placeOrder(state, action: PayloadAction<{
            payWithCash    : boolean,
            order_method   : string,
            fromMarketplace: boolean,
            tableNo?       : number
        }>) {
            state.payWithCash = action.payload.payWithCash;
            state.order_method = action.payload.order_method;
            state.fromMarketplace = action.payload.fromMarketplace;
            state.tableNo = action.payload.tableNo;
        },
        placedOrder(state, action: PayloadAction<string>) {
            state.paymentPage = action.payload;
            state.placedOrder = true;
        },
        payLater(state, action: PayloadAction<boolean>) {
            state.payLater = action.payload;
        },
        selectGroup(state, action: PayloadAction<number | undefined>) {
            state.selectedGroup = action.payload;
        },
        getDispatcherFee() {},
        getMinimumPurchase() {},
        getSmsFee() {},
        loadDispatcherFee(state, action: PayloadAction<number>) {
            state.dispatcherFee = action.payload
        },
        loadMinimumPurchase(state, action: PayloadAction<number>) {
            state.minimumPurchase = action.payload
        },
        loadSmsFee(state, action: PayloadAction<number>) {
            state.smsFee = action.payload
        },

        apiError(state, action: PayloadAction<CoreApiErrorType>) {
            state.apiError = action.payload;
        },
    },
});

export const {
    actions: orderingPageActions,
    reducer,
    name: sliceKey,
} = orderingPageSlice;
