// React, Redux libraries
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

// Component dependencies
import { merchantPageSaga } from './saga';
import { selectMerchantPage } from './selectors';
import { merchantPageActions, reducer, sliceKey } from './slice';

// Child Components
import Group from './components/Group';
import AddModal from './components/Group/AddGroupModal';

// Resources
import { Button } from "@material-ui/core";
import { ExitToApp } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { selectUser } from 'app/common/auth/selectors';
import { userActions } from 'app/common/auth/slice';
import { CONTACTLESS_API_URL } from 'app/common/core_api/resources';
import gocontactless_logo from 'assets/images/logo.png';
import { Helmet } from 'react-helmet-async';
import OperationHoursModal from './components/OperationHoursModal';
import './styles.scss';

const MerchantPage = memo(() => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: merchantPageSaga });

    const dispatch = useDispatch();
    const merchant = useSelector(selectMerchantPage);
    const user = useSelector(selectUser).merchant;
    const [urlParam, setUrlParam] = useState('')
    const [qrCode, setQrCode] = useState(user?.qrImageUrl)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        dispatch(merchantPageActions.getGroups(user?.id));
        if (user?.qrImageUrl){
            setUrlParam(user?.qrImageUrl.slice(-5))
            setQrCode(user?.qrImageUrl)
        }
        
    }, [dispatch, user?.id])

    useEffect(() => {
        if (!merchant.generating){
            setQrCode(merchant.qrCode)
            setUrlParam(merchant.qrCode.slice(-5))
            setSaving(false)
        }
    }, [merchant.generating])

    const generateQR = () => {
        setSaving(true)
        dispatch(merchantPageActions.generate({id: user?.id}));
    }

    const qrDownloadUrl = `${CONTACTLESS_API_URL}/merchant/qr/${user?.id}`;
    const paymentUrl = `${process.env.REACT_APP_PAYMENT_URL}/${user?.username}`;
    const contactlessUrl = `${process.env.PUBLIC_URL}/${user?.username}`;
    return (<>
        <Helmet>
            <title>{user?.name || 'Loading'} - Merchant CMS</title>
        </Helmet>
        <div className="merchant">
            <div className="side-bar">
                <header><img src={gocontactless_logo} alt="logo" /></header>
                <div className="main">
                    { qrCode ?
                        <><img src={qrCode} width="100%" alt="qr" />
                        <small>
                            <a
                                href     = {paymentUrl}
                                target = "_blank"
                                rel    = "noopener noreferrer"
                                style  = {{textDecoration: 'none', color: '#000'}}>
                                {paymentUrl}
                            </a>
                        </small>
                        <a href={qrDownloadUrl} className="btn btn-download" download target="_blank" rel="noreferrer">Download</a></> :
                        <button className="btn btn-primary" onClick={() => generateQR()}>{
                            !saving ? "Generate QR Code" : <div className="spinner-border text-light" role="status"></div>
                        }</button>
                            
                        
                    }
                </div>
            </div>
            <div className="content">
                { urlParam ? 
                    <>{ urlParam=="newQr" && qrCode ?
                        null :
                        <Alert
                            style={{backgroundColor: 'orange'}}
                            action={
                                <>{
                                    !saving ? <Button style={{border: '1px solid black'}} color="inherit" size="small" onClick={() => generateQR()}>
                                        UPDATE NOW
                                    </Button> :
                                    <div className="spinner-border text-dark" role="status"></div>
                                }</>
                            }
                            severity="error"
                        >
                            Your QR code is not yet updated. Click "UPDATE NOW" button on the right side of this message to get the latest GoContactless QR Code version.
                        </Alert>
                    }</> : null
                }
                
                <header>
                    <div>
                        <legend>{user?.name}</legend>
                        <div className="mb-3">
                            <OperationHoursModal />
                        </div>
                        <a
                            href     = {contactlessUrl}
                            target = "_blank"
                            rel    = "noopener noreferrer"
                            style  = {{textDecoration: 'none', color: 'inherit'}}>
                            <h6>Preview</h6>
                        </a>
                    </div>
                    <button className="btn-logout" onClick={e => dispatch(userActions.merchantLogout())}>
                        <ExitToApp /> Logout
                    </button>
                </header>
                <hr />
                <div className="main">{
                    merchant.groups ?
                    merchant.groups?.map((_, index) => <Group index={index} />) :
                    <div className="spinner-border text-primary" role="status"></div>
                }
                {merchant.groups && <AddModal />}
                </div>
            </div>
        </div>
    </>)
});

export default MerchantPage;