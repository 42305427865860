import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const HomePage = () => {
    return (<>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>
            <link rel="shortcut icon" type="image/png" href="favicon.png"/>
                
            <link rel="stylesheet" type="text/css" href="./css/bootstrap.min.css?8688"/>
            <link rel="stylesheet" type="text/css" href="style.css?2636"/>
            <link rel="stylesheet" type="text/css" href="./css/font-awesome.min.css"></link>
            <title>GoContactless</title>
        </Helmet>
        {/* Main container */}
        <div className="page-container">
            
        {/* bloc-0 */}
        <div className="bloc l-bloc" id="bloc-0">
            <div className="container bloc-sm">
                <div className="row">
                    <div className="col-md-6 order-md-1 align-self-center">
                        <img src="img/Untitled-1.jpg" className="img-fluid mx-auto d-block" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <p className="mg-md">
                            <br/>
                        </p><img src="img/222674625_831516737764912_8959681424741624803_n.png" className="img-fluid float-lg-none img-bloc-2-style" />
                        <p className="mg-md">
                            <br/>
                        </p>
                        <h2 className="mg-md">
                            Contactless and Cashless <br/>transactions for your business.<br/>
                        </h2>
                        <p className="mg-md">
                            <span className="fa fa-check icon-electric-green"></span> Accept credit/debit cards, bank transfers, e-wallets
                        </p>
                        <p className="mg-md">
                            <span className="fa fa-check icon-electric-green"></span> No app downloads for customers
                        </p>
                        <p className="mg-md">
                            <span className="fa fa-check icon-electric-green"></span> No credit card terminals, devices, or hardware needed
                        </p>
                        <p className="mg-md">
                            <span className="fa fa-check none icon-electric-green"></span> Free with a JustPayto account
                        </p><img src="img/9bf6d8_30ee25fc87e145de8cae225b7127331b_mv2.jpg" className="img-fluid float-lg-none" />
                        <p className="mg-md">
                            <br/>
                        </p><Link to="/login" className="btn btn-d btn-lg btn-clean btn-style ">Log In</Link>
                        <p className="p-style mg-sm">
                            <Link className="ltc-bleu-de-france" to="/marketplace">Log In as Marketplace Admin here</Link>&nbsp;|&nbsp;<a className="ltc-bleu-de-france float-lg-none" href="mailto:hello@justpay.to">Need a Demo?</a><br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-0 END */}
        
        {/* bloc-1 */}
        <div className="bloc l-bloc" id="bloc-1">
            <div className="container bloc-lg">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <h1 className="mg-md">
                            For Restaurants
                        </h1>
                        <p>
                            Easily let customers browse and order from a contactless menu directly on their phone. No app required. No cash-in. No hassle.
                        </p><img src="img//qr%20dining.jpeg" data-src="img/qr%20dining.jpeg" className="img-fluid mx-auto d-block lazyload" />
                        <p>
                            <br/>
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <h1 className="mg-md">
                            For Retail
                        </h1>
                        <p>
                            Elevate the guest experience by providing contactless on-premise ordering, service and product listings, and cashless payments.
                        </p><img src="img/nsplsh_5033704936787a6f767530_mv2_d_7360_4912_s_4_2.jpg" data-src="img/nsplsh_5033704936787a6f767530_mv2_d_7360_4912_s_4_2.jpg" className="img-fluid mx-auto d-block img-bloc-1-style lazyload" />
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-1 END */}
        
        {/* bloc-2 */}
        <div className="bloc l-bloc" id="bloc-2">
            <div className="container ">
                <div className="row">
                    <div className="col-12 col-11 col-lg-8 offset-lg-2">
                        <h1 className="mg-md text-center ">
                            Simple. Easy. Convenient.<br/>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-2 END */}
        
        {/* bloc-3 */}
        <div className="bloc l-bloc" id="bloc-3">
            <div className="container bloc-md">
                <div className="row">
                    <div className="col-md-6 order-md-1">
                        <img src="img/7225baee-ade7-4aba-b371-6b7829df0e68_woman-phone2.jpg" data-src="img/7225baee-ade7-4aba-b371-6b7829df0e68_woman-phone2.jpg" className="img-fluid mx-auto d-block lazyload" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="mg-md">
                            Scan
                        </h2>
                        <p>
                            Anyone can pay with their phone. No third party app downloads, no registration, no cash. Just scan the QR code, choose what to pay for, and "Pay".
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-3 END */}
        
        {/* bloc-4 */}
        <div className="bloc l-bloc" id="bloc-4">
            <div className="container bloc-sm">
                <div className="row">
                    <div className="col-md-6">
                        <img src="img/43ab678b-f37e-4e9f-ba61-233e500ef339_man-phone.jpg" data-src="img/43ab678b-f37e-4e9f-ba61-233e500ef339_man-phone.jpg" className="img-fluid mx-auto d-block lazyload" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="mg-md">
                            Select
                        </h2>
                        <p>
                            Scanning the QR code opens a product selection page. Add your products and their prices. Let customers pick quantities and additional services.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-4 END */}
        
        {/* bloc-5 */}
        <div className="bloc l-bloc" id="bloc-5">
            <div className="container bloc-sm">
                <div className="row">
                    <div className="col-md-6 order-md-1">
                        <img src="img/f7ba0692-8878-439e-a15f-03d1412f1d9e_Homepage_Hero_NEW.jpg" data-src="img/f7ba0692-8878-439e-a15f-03d1412f1d9e_Homepage_Hero_NEW.jpg" className="img-fluid mx-auto d-block lazyload" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="mg-md">
                            Pay
                        </h2>
                        <p>
                            Customers can pay with any card with a Visa or Mastercard logo, pay via bank transfer, or use their e-wallets.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-5 END */}
        
        {/* bloc-6 */}
        <div className="bloc l-bloc" id="bloc-6">
            <div className="container bloc-lg">
                <div className="row">
                    <div className="col">
                        <h1 className="mg-md text-lg-center text-md-center text-sm-center text-center">
                            How to set up Contactless ordering and Cashless payments
                        </h1>
                        <p className="text-lg-center text-md-center text-sm-center text-center">
                            Instant, efforrtless setup with zero coding
                        </p>
                        <p className="text-lg-center">
                            <br/>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <img src="img/desk-equipment.jpg" data-src="img/desk-equipment.jpg" className="img-fluid mx-auto d-block lazyload" />
                        <h3 className="mg-md">
                            Step 1
                        </h3>
                        <p>
                            Customize your order page by adding your items and prices.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <img src="img/citysky.jpg" data-src="img/citysky.jpg" className="img-fluid mx-auto d-block lazyload" />
                        <h3 className="mg-md">
                            Step 2
                        </h3>
                        <p>
                            Generate a QR code automagically. Download, print, and display your QR code.&nbsp;
                        </p>
                    </div>
                    <div className="col-md-4">
                        <img src="img/valley.jpg" data-src="img/valley.jpg" className="img-fluid mx-auto d-block lazyload" />
                        <h3 className="mg-md">
                            Step 3
                        </h3>
                        <p>
                            Receive orders and accept payments by having your customers scan the QR code.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-6 END */}
        
        {/* bloc-11 */}
        <div className="bloc l-bloc" id="bloc-11">
            <div className="container bloc-sm">
                <div className="row">
                    <div className="col">
                        <h1 className="text-center mg-sm">
                            Acccept any payment method
                        </h1><img src="img/11062b_94d3672b6d154ff7b5e6614299d8a795_mv2.jpg" data-src="img/11062b_94d3672b6d154ff7b5e6614299d8a795_mv2.jpg" className="img-fluid mx-auto d-block img-41-style lazyload" />
                        <p>
                            <br/><br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-11 END */}
        
        {/* bloc-8 */}
        <div className="bloc l-bloc" id="bloc-8">
            <div className="container ">
                <div className="row">
                    <div className="col-12 col-11 col-lg-8 offset-lg-2">
                        <h1 className="mg-md text-center ">
                            GoContactless Features<br/>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-8 END */}
        
        {/* bloc-7 */}
        <div className="bloc l-bloc" id="bloc-7">
            <div className="container bloc-md">
                <div className="row">
                    <div className="col-md-6 order-md-1">
                        <img src="img/nsplsh_7652415977455346632d55_mv2_d_6720_4480_s_4_2.jpg" data-src="img/nsplsh_7652415977455346632d55_mv2_d_6720_4480_s_4_2.jpg" className="img-fluid mx-auto d-block lazyload" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="mg-md">
                            Works with Any Device
                        </h2>
                        <p>
                            Your order page and payment page will work on any device with a camera and internet. Your customers can order and pay with their mobile phone, tablet, laptop or desktop.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-7 END */}
        
        {/* bloc-8 */}
        <div className="bloc l-bloc" id="bloc-8">
            <div className="container bloc-md">
                <div className="row">
                    <div className="col-md-6">
                        <img src="img/nsplsh_46326548664d77494f7841_mv2_d_5025_3350_s_4_2.jpg" data-src="img/nsplsh_46326548664d77494f7841_mv2_d_5025_3350_s_4_2.jpg" className="img-fluid mx-auto d-block lazyload" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="mg-md">
                            No Credit Card Terminal
                        </h2>
                        <p>
                            No need for additional investment to get a credit card terminal. No need for additional devices to accept credit card transactions.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-8 END */}
        
        {/* bloc-9 */}
        <div className="bloc l-bloc" id="bloc-9">
            <div className="container bloc-md">
                <div className="row">
                    <div className="col-md-6 order-md-1">
                        <img src="img/nsplsh_37637242326363726d506f_mv2_d_6000_4000_s_4_2.jpg" data-src="img/nsplsh_37637242326363726d506f_mv2_d_6000_4000_s_4_2.jpg" className="img-fluid mx-auto d-block lazyload" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="mg-md">
                            No app downloads needed
                        </h2>
                        <p>
                            GoContactless QR coddes open a stand-alone mobile-ready web page that is acccessible to any ddevice with internet. This allows for quick, hassle-free order taking and payment collection. You don&rsquo;t have to make your customer download apps and sign up for them.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-9 END */}
        
        {/* bloc-10 */}
        <div className="bloc l-bloc" id="bloc-10">
            <div className="container bloc-md">
                <div className="row">
                    <div className="col-md-6">
                        <img src="img/nsplsh_72573859732d744f52756f_mv2_d_4375_2917_s_4_2.jpg" data-src="img/nsplsh_72573859732d744f52756f_mv2_d_4375_2917_s_4_2.jpg" className="img-fluid mx-auto d-block lazyload" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="mg-md">
                            No Top-up / Cash-in needed
                        </h2>
                        <p>
                            Remove the additional step of requiring your customer to top-up to pay you. Avoid telling your customers complicated instructions - download an app, load money to your app, transfer funds to this account, etc.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-10 END */}
        
        {/* bloc-13 */}
        <div className="bloc l-bloc" id="bloc-13">
            <div className="container bloc-md">
                <div className="row">
                    <div className="col-md-6 order-md-1">
                        <img src="img/nsplsh_72573859732d744f52756f_mv2_d_4375_2917_s_4_21.jpg" data-src="img/nsplsh_72573859732d744f52756f_mv2_d_4375_2917_s_4_21.jpg" className="img-fluid mx-auto d-block lazyload" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="mg-md">
                            No coding required
                        </h2>
                        <p>
                            Set up your order page easily. Simply add an item, upload a photo, write a description, and set the price.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-13 END */}
        
        {/* bloc-14 */}
        <div className="bloc l-bloc " id="bloc-14">
            <div className="container bloc-md">
                <div className="row">
                    <div className="col-md-6">
                        <img src="img/nsplsh_72573859732d744f52756f_mv2_d_4375_2917_s_4_3.jpg" data-src="img/nsplsh_72573859732d744f52756f_mv2_d_4375_2917_s_4_3.jpg" className="img-fluid mx-auto d-block lazyload" />
                    </div>
                    <div className="col-md-6 align-self-center">
                        <h2 className="mg-md">
                            Integrated payments
                        </h2>
                        <p>
                            Automatic integration with JustPayto allows you to collect payments easily, and gives your customers the most convenient way to pay.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-14 END */}
        
        {/* bloc-13 */}
        <div className="bloc l-bloc" id="bloc-13">
            <div className="container bloc-md">
                <div className="row">
                    <div className="col">
                        <h1 className="mg-md text-center">
                            Convenience for you, and your customers<br/>
                        </h1>
                        <p className="text-lg-center text-md-center">
                            Receive notification of what your customers purchased, the status of their payment, and any special instructions.<br/>Your customers receive notifications confirming their orders and payment.
                        </p>
                    </div>
                </div>
                <div className="row voffset">
                    <div className="col">
                        <img src="img/11062b_3eaed5c15a574826b67b40ed89f46f94_mv2.jpg" data-src="img/11062b_3eaed5c15a574826b67b40ed89f46f94_mv2.jpg" className="img-fluid mx-auto d-block img-41-style lazyload" />
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-13 END */}
        
        {/* bloc-14 */}
        <div className="bloc l-bloc" id="bloc-14">
            <div className="container bloc-lg">
                <div className="row">
                    <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3 col">
                        <h2 className="mg-md text-center">
                            Go Contactless Now
                        </h2>
                        <h4 className="mg-md text-center">
                            Free with JustPayto Premium
                        </h4><a href="mailto:hello@justpay.to" className="btn btn-d btn-block btn-xl">Get Started</a>
                        <p>
                            <br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* bloc-14 END */}
        
        {/* bloc-15 */}
        <div className="bloc bgc-black d-bloc" id="bloc-15">
            <div className="container bloc-sm">
                <p className="text-lg-center mg-lg p-bloc-15-style">
                    <br/>GoContactless 2021<br/>
                </p>
            </div>
        </div>
        {/* bloc-15 END */}
        
        </div>
        {/* Main container END */}
        <script src="./js/jquery-3.3.1.min.js?9717"></script>
        <script src="./js/bootstrap.bundle.min.js?3805"></script>
        <script src="./js/blocs.min.js?1152"></script>
        <script src="./js/lazysizes.min.js" defer></script>
    </>)
}

export default HomePage;