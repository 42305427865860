import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { footerClasses } from "../footer";
import { selectOrderingPage } from "../selectors";
import { orderingPageActions } from "../slice";

interface Props {
    fromMarketplace: boolean
}

const Footer = ({fromMarketplace}: Props) => {
    const dispatch = useDispatch();
    const orderingPage = useSelector(selectOrderingPage);
    const [items, setItems] = useState(0);
    const [amount, setAmount] = useState('0.00');

    useEffect(() => {
        if (orderingPage.cart && orderingPage.cart.length > 0) {
            var itemCount = 0, total = 0;
            for (const cart of orderingPage.cart) {
                itemCount += cart.quantity;
                total += cart.quantity*cart.item.price;
            }
            setItems(itemCount);
            setAmount(total.toLocaleString('en-US', {minimumFractionDigits: 2}));
        } else {
            setItems(0);
            setAmount('0.00');
        }
    }, [orderingPage.cart])

    const reset = _ => dispatch(orderingPageActions.resetCart());
    const validate = e => {
        if (!orderingPage.cart || orderingPage.cart.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Your cart is empty',
                text: 'Add an item from the list'
            });
            e.preventDefault()
        }
    }

    return (
        <div className={footerClasses.footerBody}>
            <div className={footerClasses.cartSummary}>
                <small className="text-muted">{items} item/s</small>
                <small className="fw-bold">₱ {amount}</small>
            </div>
            <Link
                to={`/${orderingPage.merchant.username}/cart`}
                className={footerClasses.viewCartButton}
                onClick={validate}>
                    VIEW YOUR CART
            </Link>
            <button className="btn footer-button-secondary" onClick={reset}>RESET</button>
        </div>
    )
}

export default Footer;