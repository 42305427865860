// React, Redux libraries
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import {
    CircularProgress,
    Grid,
} from '@material-ui/core/';

export const noImageUrl = "https://d3i98j15irv1yk.cloudfront.net/no_image.webp"

// Component dependencies
import { userPageSaga } from './saga';
import { selectMarketplacePage } from './selectors';
import { marketplacePageActions, reducer, sliceKey } from './slice';

// Resources
import { Helmet } from 'react-helmet-async';
import ShowMoreText from "react-show-more-text";
import { useQuery } from 'utils/useQuery';
import './styles.scss';

const MarketplacePage = memo(() => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: userPageSaga });

    const dispatch = useDispatch();
    const marketplacePage = useSelector(selectMarketplacePage);

    const query                  = useQuery();
    const [source]               = useState(query.get('source'));
    const [varFilter, setFilter] = useState('all');
    const [array, setArray]      = useState<any[]>(["all"]);

    const username = window.location.pathname.split("/").pop()

    useEffect(() => {
        if (marketplacePage.storesLoading) {
            dispatch(marketplacePageActions.getStores(username + '...' + array));
        }
    }, [array])

    useEffect(() => {
        if (username == 'mystreethighstreet') {
            window.location.href = `/marketplace/bhs${source}`;
        }
        if (username) {
            if (marketplacePage.userLoading)    dispatch(marketplacePageActions.getUser(username));
            if (marketplacePage.storesLoading)  dispatch(marketplacePageActions.getStores(username + '...all'));
            if (marketplacePage.filtersLoading) dispatch(marketplacePageActions.getFilters(username));
        }
    }, [])

    const filter_all = () => {
        dispatch(marketplacePageActions.getStores(username + '...all'));
        dispatch(marketplacePageActions.getFilters(username));
        setFilter("all")
        setArray(["all"])
    } 

    const custom_filter = (tag) => {
        tag = tag.replace(' ', '');
        setFilter(tag)
        
        var x = array.find(arr => arr === tag);
        if (x){
            var index = array.indexOf(tag);
            if (index > -1) {
                array.splice(index, 1)
                setArray([...array]);
            }
        }else{
            setArray([...array, tag])
        }
    } 

    const filterIndicate = (filter) => {
        let x = array.find(arr => arr === filter);
        return x
    }

    const redirectTo = (path) => {
        window.location = path
    } 

    const convertToArray = (array) => {
        return(array)
    } 

    const storeUrl = username => `${process.env.REACT_APP_PAYMENT_URL}/${username}${window.location.search}${window.location.search ? '&' : '?'}marketplace=true`

    return (<>
        <Helmet><title>{marketplacePage.user['name'] || 'Loading'} - Marketplace</title></Helmet>
        <div className="marketplace">
            
            {marketplacePage.user ? <div className="banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${marketplacePage.user['avatar_url']})` }}>
                    <div className="banner-text">
                        <h1 style={{fontSize:'50px'}}>{marketplacePage.user['name']}</h1>
                        <p>{marketplacePage.user['description']}</p>
                    </div>
                </div> : <div className="banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../assets/images/bg_merchant_login.jpeg')` }}>
                    <div className="banner-text">
                        <h1 style={{fontSize:'50px'}}>Establishment was not found</h1>
                        <p>Sorry, we cannot find the establishment you're looking for. Make sure you're at the right URL.</p>
                    </div>
                </div>
            }
            <div className="content">
                <header>
                    <div className="header">
                        { varFilter=="all" ? <button style={{backgroundColor: "rgb(221, 221, 221)"}} className="btn-filter" onClick={() => filter_all()}>all</button> : <button className="btn-filter" onClick={() => filter_all()}>all</button>}
                        {marketplacePage.filters ? marketplacePage.filters?.map(filter =>
                            <>{ filterIndicate(filter) ? <button style={{backgroundColor: "rgb(221, 221, 221)"}} className="btn-filter" onClick={() => custom_filter(filter)}>{filter}</button> : <button className="btn-filter" onClick={() => custom_filter(filter)}>{filter}</button>}</>
                        ) : <></>}
                    </div>
                </header>
                <div className="main">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4" style={{width: "100%"}}>
                        {marketplacePage.storesLoading ? 
                            <Grid container justify="center" alignItems="center" alignContent="center" style={{padding: 20}}>
                                <Grid item>
                                    <CircularProgress color="primary" size={20}/>
                                </Grid>
                            </Grid> :
                            <>{marketplacePage.stores ? marketplacePage.stores.map(store =>
                                <div className="col">
                                    <div className="card shadow h-100" style={{ borderRadius: "3%"}}>
                                        <div className="the-banner" style={{ cursor: "pointer", backgroundImage: `url(${store.logo_url ? store.logo_url : noImageUrl})` }} onClick={() => redirectTo(storeUrl(store.contactless_url))}></div>
                                            <div className="card-body">
                                                <h6 className="card-title d-flex justify-content-around"><a className="non-link" href={storeUrl(store.contactless_url)}>{store.name}</a></h6>
                                                <ShowMoreText
                                                    lines = {5}
                                                    more  = {<span><br/>Show more</span>}
                                                    less  = {<span><br/>Show less</span>}
                                                    className = "card-text text-muted"s
                                                >
                                                    <small dangerouslySetInnerHTML = {{ __html: store.description?.replaceAll('\n', '<br/>')}}/>
                                                </ShowMoreText>
                                            </div>
                                            <div className="card-footer footer" style={{ borderBottomLeftRadius: "3%", borderBottomRightRadius: "3%" }}>
                                                {store.tags? store.tags.map(tags =>
                                                    <button className="category">{tags}</button>) : <></>
                                                }
                                        </div>
                                    </div>
                                </div>) : <div className="container4"><p className="text-muted">No Results Found</p></div>
                            }</>         
                        }       
                    </div>
                </div>
                <div style={{marginBottom: "20px"}}>&nbsp;</div>
            </div>
        </div>
    </>)
});

export default MarketplacePage;