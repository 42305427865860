import { PayloadAction } from '@reduxjs/toolkit';
import { CoreApiErrorType } from 'types/CoreApiErrorType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState, Group, Item, User, Store, Filter
} from './types';


// The initial state of the ReportsPage container
export const initialState: ContainerState = {
    username: '',
    user: [],
    filters: [],
    groupsLoading: true,
    userLoading: true,
    filtersLoading: true,
    storesLoading: true,
    apiError: undefined,
    showAddModal: false,
    showEditModal: false,
};

const marketplacePageSlice = createSlice({
    name: 'marketplacePage',
    initialState,
    reducers: {
        getUser(state, action: PayloadAction<string | undefined>) {
            state.userLoading = true;
        },
        userLoaded(state, action: PayloadAction<Array<User>>) {
            state.user = action.payload;
            state.userLoading = false;
        },
        getStores(state, action: PayloadAction<string | undefined>) {
            state.storesLoading = true;
        },
        storesLoaded(state, action: PayloadAction<Array<Store>>) {
            state.stores = action.payload;
            state.storesLoading = false;
        },
        getFilters(state, action: PayloadAction<string | undefined>) {
            state.filtersLoading = true;
        },
        filtersLoaded(state, action: PayloadAction<Array<Filter>>) {
            state.filters = action.payload;
            state.filtersLoading = false;
        },

        addStore(state, action: PayloadAction<{
            store?: Store
        }>) {},
        addedStore(state, action: PayloadAction<{
            store?: Store
        }>) {},
        saveStore(state, action: PayloadAction<{
            store?: Store
        }>) {},
        savedStore(state, action: PayloadAction<{
            store?: Store
        }>) {},
        deleteStore(state, action: PayloadAction<{
            id: number
        }>) {},
        deletedStore(state, action: PayloadAction<{
            id: number
        }>) {},
        getGroups(state, action: PayloadAction<number | undefined>) {
            state.marketplace_user_id = action.payload;
            state.groupsLoading = true;
        },
        groupsLoaded(state, action: PayloadAction<Array<Group>>) {
            state.groups = action.payload;
            state.groupsLoading = false;
        },
        getItems(state, action: PayloadAction<number>) {
            if (state.groups) {
                state.groups[action.payload].itemsLoading = true;
            }
        },
        itemsLoaded(state, action: PayloadAction<{
            group_index: number,
            items: Array<Item>
        }>) {
            const data = action.payload
            if (state.groups) {
                state.groups[data.group_index].items = data.items
                state.groups[data.group_index].itemsLoading = false
            }
        },
        setAvailable(state, action: PayloadAction<{
            groupIndex: number,
            index: number,
            item: Item
        }>) {},
        availableUpdated(state, action: PayloadAction<{
            groupIndex: number,
            index: number,
            available: boolean
        }>) {
            const data = action.payload
            if (state.groups) {
                const items = state.groups[data.groupIndex].items
                if (items) {
                    items[data.index].available = data.available ? 'yes' : 'no'
                    items[data.index].availableUpdating = true
                }
            }
        },
        saveGroup(state, action: PayloadAction<{
            index?: number,
            group?: Group
        }>) {},
        savedGroup(state, action: PayloadAction<{
            index?: number,
            group?: Group
        }>) {
            const {index, group} = action.payload
            if (state.groups && group) {
                const groups = [...state.groups]
                if (index !== undefined) {
                    const items = groups[index].items;
                    groups[index] = group;
                    groups[index].items = items;
                }
                else {
                    groups.push(group)
                }
                state.groups = groups;
            }
        },
        deleteGroup(state, action: PayloadAction<{index: number, id: number}>) {},
        deletedGroup(state, action: PayloadAction<{index: number, id: number}>) {
            const {index} = action.payload;
            if (state.groups) {
                state.groups.splice(index, 1);
            }
        },
        saveItem(state, action: PayloadAction<{
            groupIndex: number,
            index?: number,
            item: Item
        }>) {},
        savedItem(state, action: PayloadAction<{
            groupIndex: number,
            index?: number,
            item: Item
        }>) {
            const {groupIndex, index, item} = action.payload
            if (state.groups && state.groups[groupIndex]) {
                const group = state.groups[groupIndex];
                if (group.items) {
                    if (index !== undefined) {
                        group.items[index] = item;
                    } else {
                        group.items.push(item)
                    }
                }
            }
        },
        deleteItem(_, action: PayloadAction<{groupIndex: number, index: number, id: number}>) {},
        deletedItem(state, action: PayloadAction<{groupIndex: number, index: number}>) {
            const {groupIndex, index} = action.payload
            if (state.groups && state.groups[groupIndex]) {
                state.groups[groupIndex].items?.splice(index, 1);
            }
        },
        
        apiError(state, action: PayloadAction<CoreApiErrorType>) {
            state.groupsLoading = false;
            state.apiError = action.payload;
        },
    },
});

export const {
    actions: marketplacePageActions,
    reducer,
    name: sliceKey,
} = marketplacePageSlice;
