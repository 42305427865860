// React, Redux libraries
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'

// Component Dependencies
import { Group } from '../../types';
import { sliceKey, reducer, userPageActions } from '../../slice'
import { userPageSaga } from '../../saga'
import { selectUserPage } from '../../selectors';

import 'styles/modal.scss'
import { selectUser } from 'app/common/auth/selectors';
import ConfirmActionModal from '../ConfirmActionModal';

import { 
    TextField, 
    InputAdornment,
    CircularProgress,
} from '@material-ui/core/';
import { STORE_NAME } from 'app/common/core_api/resources';

export const noImageUrl = "https://d3i98j15irv1yk.cloudfront.net/no_image.webp"
interface Props {
    data: any
}

const EditModal = (props: Props) => {
    //useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: userPageSaga });
    
    const dispatch = useDispatch();
    const user     = useSelector(selectUser).user;
    const userPage = useSelector(selectUserPage);

    const [show, setShow]               = useState(false);
    const [saving, setSaving]           = useState(false);
    const [deleting, setDeleting]       = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [name, setName]               = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [image, setImage]             = useState<string>('');
    const [url, setURL]                 = useState<string>('');
    const [contact, setContact]         = useState<string>('');
    const [tags, setTags]               = useState<string>('');
    const [available, setAvailable]     = useState<string>('');

    const {getRootProps, getInputProps} = useDropzone();

    const deleteHandler = (data) => {
        Swal.fire({
            title            : 'Are you sure you want to remove this establishment?',
            showDenyButton   : true,
            showCancelButton : false,
            confirmButtonText: `Yes`,
            denyButtonText   : `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                setDeleting(true);
                dispatch(userPageActions.deleteStore({id: data.id}))
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    };

    const save = _ => {
        var value          = tags.replace(/\s/g, "");
        var words          = value.split(",");
        var urlRestricted  = url.includes("admin")
        var urlRestricted1 = url.includes("dashboard")
        if (!userPage.selectedStoreName) {
            Swal.fire({
                icon : 'error',
                title: 'Oops...',
                text : 'Username does not exist or wait for the name to show',
            })
        }
        else if(!description || !url || !contact){
            Swal.fire({
                icon : 'error',
                title: 'Oops...',
                text : 'Please fill all required fields (*)',
            })
        }else if(urlRestricted || urlRestricted1){
            Swal.fire({
                icon : 'error',
                title: 'Oops...',
                text : 'Invalid GoContactless URL. `Admin` and `dashboard` is a system reserved word and cannot be used.',
            })
        }else if(words.length > 5){
            Swal.fire({
                icon : 'error',
                title: 'Oops...',
                text : 'Only maximum of 5 tags are allowed',
            })
        }else if(words.includes("") && value!=""){
            Swal.fire({
                icon : 'error',
                title: 'Oops...',
                text : 'Invalid tags. Please make sure there is no blank tag in between, before and after commas.',
            })
        }else{
            setSaving(true);
            setTags(tags.replace(/\s/g, " "))
            if (user) {
                dispatch(userPageActions.saveStore({
                    store:{
                        id                       : props.data.id,
                        marketplace_user_id      : user.id,
                        name                     : name || '',
                        description              : description || '',
                        contact_number           : contact || '',
                        contactless_url          : url || '',
                        tags                     : tags || '',
                        logo_url                 : image || '',
                        available                : available || '',
                    }
                }))
            }
        }
    }
    useEffect(() => {
        setSaving(false);
        setName(props.data.name);
        setDescription(props.data.description);
        setImage(props.data.logo_url);
        setURL(props.data.contactless_url);
        setContact(props.data.contact_number);
        setTags(props.data.tags);
        setAvailable(props.data.available)
        setDeleting(false);
        if (userPage.apiError === undefined) {
            setShow(false);
        }
    }, [userPage.trigger])

    useEffect(() => {
        setSaving(false);
        setDeleting(false);
    }, [userPage.validationError, userPage.apiError])

    useEffect(() => {
        dispatch(userPageActions.loadStoreName(''))
        dispatch(userPageActions.getStoreName(url))
    }, [url])

    useEffect(() => {
        if (show) {
            dispatch(userPageActions.loadStoreName(''))
            dispatch(userPageActions.getStoreName(url))
        }
    }, [show])
    
    const deleteGroup = _ => {
        setShow(false);
        setShowConfirmModal(true);
    };
    const cancelDelete = _ => {
        setShow(true);
        setShowConfirmModal(false);
    };
    const confirmDelete = _ => {
        setShowConfirmModal(false);
        setShow(true);
        setDeleting(true);
        //dispatch(userPageActions.deleteGroup({
            //index: index,
            //id: group?.id || -1
        //}));
    };

    const getImageUri = e => {
        if (e.target.files) {
            const file = e.target.files[0];
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            if (file.size > 1000000) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File size exceeds limit, it should be not more than 1MB',
                })
                return;
            }
            if (!['jpg', 'jpeg', 'png'].includes(ext)) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File type is not allowed',
                })
                return;
            }
            
            if (file) {
                var reader = new FileReader();
                reader.onload = function (data) {
                    setImage(String(data.target?.result));
                }
                reader.readAsDataURL(file);
            }
            else {
                console.log('File not found');
            }
        }
    }

    var inputBox = document.getElementById("contact");
    var invalidChars = ["e"];
      
    if (contact){
        inputBox!.addEventListener("keydown", function(e) {
            if (invalidChars.includes(e.key)) {
                e.preventDefault();
            }
          });
    }

    const isNumber = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        if (key.length == 0) return;
        var regex = /^[ +)(0-9,\b-]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    
    return (<>
    <div className={`modal fade ${(show && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={e => setShow(false)}>
        <div className="modal-dialog modal-dialog-centered modal-lg" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-body">
                    <h4 className="modal-title text-center mb-3">
                        Edit Establishment
                    </h4>
                    <div className="px-5">
                    <div className="form-group mb-3">
                            <label htmlFor="name">
                                {!userPage.selectedStoreName && url && <><CircularProgress style={{width: '15px', height:'15px'}} />{' '}</>}
                                Name *
                            </label>
                            <input disabled
                                id          = "name"
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {50}
                                value       = {userPage.selectedStoreName}
                                onChange    = {e => setName(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description">Description *</label>
                            <textarea
                                id          = "description"
                                className   = "form-control"
                                maxLength   = {150}
                                value       = {description}
                                onChange    = {e => setDescription(e.target.value)}>
                            </textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="contact">Contact Number *</label>
                            <input
                                id          = "contact"
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {50}
                                value       = {contact}
                                onChange    = {e => setContact(e.target.value)}
                                onKeyPress  = {e => isNumber(e)} />
                        </div>
                        <div className="form-group mb-3">
                        <label htmlFor="url" style={{ marginBottom: '5px' }}>GoContactless URL *</label>
                            <TextField
                                id          = "url"
                                className   = "form-control"
                                InputProps  = {{
                                    startAdornment: <InputAdornment position="start">{process.env.REACT_APP_URL}/</InputAdornment>,
                                }}
                                variant     = "outlined"
                                size        = "small"
                                value       = {url}
                                onChange    = {e => setURL(e.target.value)}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="tags">Tags (max 5, use comma as seperator)</label>
                            <input
                                id          = "tags"
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {150}
                                value       = {tags}
                                onChange    = {e => setTags(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="image">Image</label>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} onChange={getImageUri} />
                                {!image ?
                                    <div className="text-center">
                                        <p>Click to upload</p>
                                        <small>jpg, jpeg, png (recommended 1080 x 600, max 1 mb)</small>
                                    </div> :
                                    <img src={image ? image : noImageUrl} alt={name} width="100%"/>
                                }
                            </div>
                        </div>
                        <button className="btn btn-primary w-100 mb-2" onClick={save} disabled={saving || deleting}>{
                            !saving ? "SAVE" :
                            <div className="spinner-border text-light" role="status"></div>
                        }</button>
                        <button className="btn btn-link text-danger w-100  mb-2" onClick={() => deleteHandler(props.data)} disabled={saving || deleting}>{
                            !deleting ? "DELETE ESTABLISHMENT?" :
                            <div className="spinner-border text-danger" role="status"></div>
                        }</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {show && <div className={`modal-backdrop fade ${(show && 'show')}`}></div>}
    <button className="btn-edit" onClick={e => setShow(true)}>
        Edit
    </button>
    {showConfirmModal && <ConfirmActionModal onCancel={cancelDelete} onConfirm={confirmDelete}/>}
    </>)
};

export default EditModal;