import { useEffect, useState } from 'react';
import useCountry from 'utils/country';

interface Props {
    onCountryChange?: (country) => void
    onNumberChange? : (e) => void
}

const MobileNumber = ({onCountryChange, onNumberChange}: Props) => {
    const countries = useCountry();
    const [open, setOpen]     = useState(false);
    const [shown, setShown]   = useState<object[]>([]);
    const [search, setSearch] = useState('');
    const [country, setCountry] = useState<object>()

    useEffect(() => {
        setShown(countries.filter(country => (
            country['name'].toLowerCase().includes(search.toLowerCase()) ||
            country['mobile_code'].toLowerCase().includes(search.toLowerCase()) ||
            country['code'].toLowerCase().includes(search.toLowerCase())
        )))
        if (!country) {
            setCountry(countries.find(c => c.code == 'PH'))
        }
    }, [countries, search])

    const onChange = e => {
        e.target.value = e.target.value.replace(/[^\d]/g, '')
        onNumberChange && onNumberChange(e)
    }

    return (
        <div className='input-group mobile-number'>
            <div className={`input-group-prepend dropdown ${open? 'show': ""}`}>
                <button
                    className = "prepend-icon btn btn-white w-100 dropdown-toggle d-flex justify-content-between align-middle align-items-center"
                    type      = "button"
                    onClick   = {() => setOpen(!open)}
                >
                    <img src={country?.['logo_url']} style={{position: 'absolute', left: '15px', height: '35%'}}/>
                    <span>{country?.['mobile_code'] || ""}</span>
                </button>
            </div>
            <input onChange={onChange}
                type        = "text"
                id          = "number"
                className   = "form-control"
            />
            <div className={`dropdown-menu w-100 ${open? 'show': ""}`} style={{marginTop: '38px'}}>
                <div className="form-group px-3 mb-2">
                    <input onChange = {e => setSearch(e.target.value)}
                        type        = "text"
                        className   = "prepend-icon form-control"
                    />
                </div>
                <div className="dropdown-menu-content overflow-auto">
                    {shown.map(country => <button className="btn dropdown-item" type = "button" onClick={() => {
                        setOpen(false)
                        setCountry(country)
                        onCountryChange && onCountryChange(country)
                    }}>
                        {country['name']} {country['mobile_code']} ({country['code']})
                    </button>)}
                </div>
            </div>
        </div>
    )
}

export default MobileNumber