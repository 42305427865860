import { Route, useParams } from "react-router-dom";
import Products from './Products';
import ProductsFooter from './Products/Footer';
import Cart from './Cart';
import CartFooter from './Cart/Footer';
import Contacts from "./Contacts";
import ContactsFooter from "./Contacts/Footer";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive';

import { orderingPageActions, reducer, sliceKey } from "./slice";
import { orderingPageSaga } from "./saga";
import { selectOrderingPage } from "./selectors";

import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import OrderPlaced from "./OrderPlaced";
import { OrderingPageState } from "./types";
import { NotFoundPage } from "../NotFoundPage";
import { Helmet } from "react-helmet-async";
import { useQuery } from "utils/useQuery";
import NotLivePage from "../NotLivePage";
import { storeIsOpen } from "utils";
import MerchantClosed from "./MerchantClosed";
import TableNumberModal from "./TableNumberModal";

const BrowseModal = ({openBrowse, orderingPage, selectGroup, setOpenBrowse}: {
    openBrowse: boolean,
    orderingPage: OrderingPageState,
    selectGroup: (id: any) => void,
    setOpenBrowse: React.Dispatch<React.SetStateAction<boolean>>
}) => (<>
    {/* BROWSE BUTTON */}
    <div className="row p-4 justify-content-center">
        <div className="col-12 col-md-9 col-lg-4">
            <button role="button" className="btn browse-button" onClick={_ => setOpenBrowse(true)}>
                <span>{
                    orderingPage.selectedGroup === undefined ? 'Browse' :
                    orderingPage.groups?.find(group => group.id === orderingPage.selectedGroup)?.name
                }</span>
                <i className="fas fa-caret-down fa-fw"></i>
                <FontAwesomeIcon icon={faAngleDown}  />
            </button>
        </div>
    </div>
    {/* BROWSE MODAL */}
    <div className={`browse modal fade ${(openBrowse && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={_ => setOpenBrowse(false)}>
        <div className="modal-dialog modal-dialog-centered" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-body px-4">
                    <h6 className="browse-title">Browse</h6><hr/>
                    <a role="button" onClick={_ => selectGroup(undefined)}>
                        All
                    </a><hr/>
                    {orderingPage.groups?.map(
                        group => <>
                            <a role="button" onClick={_ => selectGroup(group.id)}>
                                {group.name}
                            </a><hr/>
                        </>)
                    }
                </div>
            </div>
        </div>
    </div>
</>)

const OrderingPages = memo(() => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: orderingPageSaga });

    const query        = useQuery();
    const dispatch     = useDispatch();
    const orderingPage = useSelector(selectOrderingPage);
    const isDesktop    = useMediaQuery({ query: `(min-width: 769px)` });
    const isTablet     = useMediaQuery({ query: `(min-width: 426px, max-width: 768px)` });
    const isMobile     = useMediaQuery({ query: `(max-width: 425px)` });
    const { merchantCode } = useParams<{merchantCode: string}>();
    const [ loading, setLoading ] = useState(true);
    const [ openBrowse, setOpenBrowse] = useState(false);
    const [ merchantNotFound, setMerchantNotFound ] = useState(false);
    const [ merchantNotLive , setMerchantNotLive  ] = useState(false);
    const [ merchantClosed  , setMerchantClosed   ] = useState(false);
    const [fromQR, setFromQR]                   = useState(false);
    const [fromMarketplace, setFromMarketplace] = useState(false);
    const [tableNo, setTableNo]                 = useState(0);

    useEffect(() => {
        if (Array.from(query).length > 0) {
            sessionStorage.setItem('params', JSON.stringify({
                source     : query.get('source')?.toLowerCase(),
                marketplace: query.get('marketplace') == 'true',
                tableNo    : (
                    query.get('table_no') &&
                    query.get('table_no') !== '0' &&
                    !isNaN(+(query.get('table_no') || '')) ?
                    +(query.get('table_no') || '') : undefined
                )
            }))
        }
        const string_params = sessionStorage.getItem('params');
        if (string_params) {
            const params = JSON.parse(sessionStorage.getItem('params') || '');
            setFromQR         (params ? params.source == 'qr' : false);
            setFromMarketplace(params ? params.marketplace    : false);
            setTableNo        (params ? params.tableNo        : 0);
        }
    }, [])

    useEffect(() => {
        if (fromMarketplace) {
            dispatch(orderingPageActions.getDispatcherFee());
        }
        dispatch(orderingPageActions.getMinimumPurchase());
        dispatch(orderingPageActions.getSmsFee());
    }, [fromMarketplace])

    useEffect(() => {
        if (!orderingPage.merchant.id) {
            dispatch(orderingPageActions.getMerchant(merchantCode));
            setInterval(() => dispatch(orderingPageActions.getMerchant(merchantCode)), 15000);
        }
    }, [merchantCode, dispatch])

    useEffect(() => {
        if (orderingPage.merchant) {
            setLoading(!orderingPage.merchant.id && !orderingPage.apiError);
            setMerchantNotLive(orderingPage.merchant.status != 'live');
            setMerchantNotFound(orderingPage.apiError !== undefined);
            setMerchantClosed(!storeIsOpen(orderingPage.merchant));
        }
    }, [orderingPage.merchant, orderingPage.apiError])

    const location = window.location.pathname.split('/');
    const path = `/${merchantCode}`;
    const routes = [
        {
            path: `${path}`,
            content: <Products />,
            footer : <ProductsFooter fromMarketplace={fromMarketplace} />
        },
        {
            path: `${path}/cart`,
            content: <Cart />,
            footer : <CartFooter desktop={isDesktop} tablet={isTablet} mobile={isMobile} fromQR={fromQR} fromMarketplace={fromMarketplace} tableNo={tableNo}/>
        },
        {
            path: `${path}/contacts`,
            content: <Contacts fromMarketplace={fromMarketplace} fromQR={fromQR} />,
            footer : <ContactsFooter desktop={isDesktop} tablet={isTablet} mobile={isMobile} fromQR={fromQR} fromMarketplace={fromMarketplace} tableNo={tableNo}/>
        },
        {
            path: `${path}/place-order`,
            content: <OrderPlaced />,
            footer : <></>
        }
    ];

    const selectGroup = id => {
        setOpenBrowse(false);
        dispatch(orderingPageActions.selectGroup(id))
    }

    window.onbeforeunload = e => {
        if (orderingPage.merchant.id && !orderingPage.placedOrder) {
            window.onbeforeunload = null;
            return '';
        }
    }

    if (loading) {
        return <div>Loading...</div>
    }
    if (merchantNotFound) {
        return <NotFoundPage />
    }
    if (merchantNotLive) {
        return <NotLivePage name={orderingPage.merchant.name} />
    }
    if (merchantClosed || orderingPage.merchant.closed) {
        return <MerchantClosed merchant={orderingPage.merchant} />
    }

    return <>
        <Helmet>
            <title>{orderingPage.merchant.name}</title>
        </Helmet>
        <div className="ordering">
            {fromQR && tableNo && <TableNumberModal fromMarketplace={fromMarketplace} tableNo={tableNo || 0} />}
            <div className="card header shadow border-0">
                <div className="card-body">
                    <h4 className="card-title">{orderingPage.merchant.name}</h4>
                    <p className="card-text">{!fromQR ? 'Online' : 'Contactless'} Ordering</p>
                </div>
                {location[2] && location[2] === 'cart' &&
                    <div className="order-summary">
                        ORDER SUMMARY
                    </div>
                }
                {location[2] && location[2] === 'contacts' &&
                    <div className="order-summary">
                        PENDING PAYMENT
                    </div>
                }
                {(!location[2] || location[2] === '') &&
                    <BrowseModal
                        openBrowse    = {openBrowse}
                        orderingPage  = {orderingPage}
                        selectGroup   = {selectGroup}
                        setOpenBrowse = {setOpenBrowse} />
                }
            </div>
            <div className="content" style={{minHeight: '300px'}}>
                {routes.map((route, index) => <Route exact path={route.path} render={() => route.content} key={index} />)}
            </div>
            <div className="card footer shadow-lg">
                {routes.map((route, index) => <Route exact path={route.path} render={() => route.footer} key={index} />)}
            </div>
            {openBrowse && <div className={`modal-backdrop fade overflow-hidden ${(openBrowse && 'show')}`} onClick={_ => setOpenBrowse(false)}></div>}
        </div>
    </>
});

export default OrderingPages;