import { PayloadAction } from '@reduxjs/toolkit';
import { CoreApiErrorType } from 'types/CoreApiErrorType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState, Group, Item, User, Store
} from './types';


// The initial state of the ReportsPage container
export const initialState: ContainerState = {
    username: '',
    user: [],
    userLoading: true,
    groupsLoading: true,
    storesLoading: true,
    apiError: undefined,
    validationError: 0,
    showAddModal: false,
    showEditModal: false,
    posting: true,
    availability: true,
    trigger: 0,
    available_trigger: 0,
};

const userPageSlice = createSlice({
    name: 'userPage',
    initialState,
    reducers: {
        getUser(state, action: PayloadAction<string | undefined>) {
            state.username = action.payload;
            state.userLoading = true;
        },
        userLoaded(state, action: PayloadAction<Array<User>>) {
            state.user = action.payload;
            state.userLoading = false;
        },
        saveUser(state, action: PayloadAction<{
            user?: User
        }>) {
            state.posting = true;
        },
        savedUser(state, action: PayloadAction<number>) {
            state.posting = false;
            state.trigger = action.payload;
        },
        getStores(state, action: PayloadAction<number | undefined>) {
            state.marketplace_user_id = action.payload;
            state.storesLoading = true;
        },
        storesLoaded(state, action: PayloadAction<Array<Store>>) {
            state.stores = action.payload;
            state.storesLoading = false;
        },
        addStore(state, action: PayloadAction<{
            store?: Store
        }>) {
            state.posting = true;
        },
        addedStore(state, action: PayloadAction<number>) {
            state.posting = false;
            state.trigger = action.payload;
        },
        saveStore(state, action: PayloadAction<{
            store?: Store
        }>) {
            state.posting = true;
        },
        savedStore(state, action: PayloadAction<number>) {
            state.posting = false;
            state.trigger = action.payload;
        },
        deleteStore(state, action: PayloadAction<{
            id: number
        }>) {
            state.posting = true;
        },
        deletedStore(state, action: PayloadAction<number>) {
            state.posting = false;
            state.trigger = action.payload;
        },
        setAvailable(state, action: PayloadAction<{
            id: number,
        }>) {
            state.availability = true;
        },
        availableUpdated(state, action: PayloadAction<number>) {
            state.availability = false;
            state.available_trigger = action.payload;
        },
        validationError(state, action: PayloadAction<number>) {
            state.validationError = action.payload;
        },
        toggleStore(state, action: PayloadAction<string>) {},
        getStoreName(state, action: PayloadAction<string>) {},
        loadStoreName(state, action: PayloadAction<string>) {
            state.selectedStoreName = action.payload;
        },
        apiError(state, action: PayloadAction<CoreApiErrorType>) {
            state.posting = false;
            state.apiError = action.payload;
        },
    },
});

export const {
    actions: userPageActions,
    reducer,
    name: sliceKey,
} = userPageSlice;
