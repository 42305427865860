import { selectUser } from 'app/common/auth/selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'styles/modal.scss'
import { selectMerchantPage } from '../../selectors';
import { merchantPageActions } from '../../slice';

const AddModal = () => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [saving, setSaving]           = useState(false);

    const dispatch     = useDispatch();
    const user         = useSelector(selectUser).merchant;
    const merchantPage = useSelector(selectMerchantPage);

    useEffect(() => {
        setSaving(false);
        if (merchantPage.apiError === undefined) {
            setName('');
            setDescription('');
            setShow(false);
        }
    }, [merchantPage])
    
    const save = _ => {
        setSaving(true);
        if (user) {
            dispatch(merchantPageActions.saveGroup({group:{
                user_info_id: user.id,
                name          : name || '',
                description   : description || '',
                items         : [],
            }}))
        }
    }

    return (<>
    <div className={`modal fade ${(show && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={e => setShow(false)}>
        <div className="modal-dialog modal-dialog-centered" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-body">
                    <h4 className="modal-title text-center mb-3">
                        Add Group
                    </h4>
                    <div className="px-5">
                        <div className="form-group mb-3">
                            <label htmlFor="name">Group Name</label>
                            <input
                                id          = "name"
                                type        = "text"
                                className   = "form-control"
                                placeholder = "Group Name"
                                maxLength   = {50}
                                value       = {name}
                                onChange    = {e => setName(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description">Description</label>
                            <textarea
                                id          = "name"
                                className   = "form-control"
                                placeholder = "Description"
                                value       = {description}
                                onChange    = {e => setDescription(e.target.value)}>
                            </textarea>
                        </div>
                        <button className="btn btn-primary w-100 mb-2" onClick={save} disabled={saving}>{
                            !saving ? "SAVE" :
                            <div className="spinner-border text-light" role="status"></div>
                        }</button>
                        <button className="btn btn-link text-primary w-100  mb-2" onClick={e => setShow(false)} disabled={saving}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {show && <div className={`modal-backdrop fade ${(show && 'show')}`} onClick={e => setShow(false)}></div>}
    <button className="btn-add" onClick={e => setShow(true)}>
        Add Group
    </button>
    </>)
}

export default AddModal;