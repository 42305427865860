import { COUNTRIES } from "app/common/core_api/resources"
import { useEffect, useState } from "react"

const useCountry = () => {
    const [countries, setCountries] = useState<any[]>([])

    useEffect(() => {
        if (countries.length == 0) {
            fetch(COUNTRIES).then(
                resp => resp.json()
            ).then(
                countries => setCountries(countries)
            )
        }
    })

    return countries
}

export default useCountry