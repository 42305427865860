import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import GoogleAnalytics from 'app/common/ga';

export function NotFoundPage() {
    useEffect(() => {
        GoogleAnalytics.send('pageview')
    }, [])
    return (
        <>
            <Helmet>
                <title>404 Page Not Found</title>
                <meta name="description" content="Page not found" />
            </Helmet>
            <Wrapper>
                <Title>
                    4<span role="img" aria-label="Crying Face">😢</span>4
                </Title>
                <P>Page not found.</P>
            </Wrapper>
        </>
    );
}

export const P = styled.p`
    font-size: 1rem;
    line-height: 1.5;
    color: black;
    margin: 0.625rem 0 1.5rem 0;
`;

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 320px;
`;

const Title = styled.div`
    margin-top: -8vh;
    font-weight: bold;
    color: black;
    font-size: 3.375rem;

    span {
        font-size: 3.125rem;
    }
`;
