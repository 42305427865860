/**
 * Asynchronously loads the component for AdminLoginPage
 */

 import { lazyLoad } from 'utils/loadable';

const AdminLoginPage = lazyLoad(
   () => import('./index'),
   module => module.AdminLoginPage,
 );
 

 export default AdminLoginPage;